'use client';

import { AppShell, Box, Flex, Heading, LinkButton } from '@company/ui/components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const headerHeight = '48px';

export const PublicLayout = ({ children }: { children: React.ReactNode }) => {
  const { _ } = useLingui();

  return (
    <AppShell
      appbar={
        <Flex
          h={headerHeight}
          justify="space-between"
          align={'center'}
          bgColor={'gray.50'}
          py={2}
          px={4}
        >
          <Heading size={'md'}>{_(msg`Company`)}</Heading>
          <Box>
            <LinkButton href="/sign-in" colorScheme="primary" variant="outline" mr={2} size="sm">
              <Trans>Login</Trans>
            </LinkButton>
          </Box>
        </Flex>
      }
      main={<>{children}</>}
    ></AppShell>
  );
};
